import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store';
import VueWechatTitle from 'vue-wechat-title';

import '@/directive';
import '@/filters';

import 'windi.css';
import '@/styles/main.scss';
/* 按需引入vant */
import '@/utils/vant';
import '@/utils/identification';
Vue.config.productionTip = false;

Vue.use(VueWechatTitle);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
