import { IBaseProduct } from '@/api/type/base';
import { calcMoney, calcMoneyCent } from '@/filters/calcMoney';
import { MerchantModule } from '@/store/modules/Merchant';
import * as math from 'mathjs';
type calcFnType = (num1: number | string, num2: number | string) => string;
/* 加 */
export const MathAdd: calcFnType = (num1, num2) => {
  const value = math.add(math.bignumber(num1), math.bignumber(num2));
  return math.format(value);
};

/* 减法 */
export const MathSubtract: calcFnType = (num1, num2) => {
  const value = math.subtract(math.bignumber(num1), math.bignumber(num2));
  return math.format(value);
};

/* 乘法 */
export const MathMultiply: calcFnType = (num1, num2) => {
  const value = math.multiply(math.bignumber(num1), math.bignumber(num2));
  return math.format(value);
};

/* 除法 */
export const MathDivide: calcFnType = (num1, num2) => {
  const value = math.divide(math.bignumber(num1), math.bignumber(num2));
  return math.format(value);
};

/* 读取折扣金额 */
export const calcDeductPriceAndCredit = (priceCent: number, detail: Pick<IBaseProduct, 'inputRules'>) => {
  let credit = calcDeductCredit(detail);
  /* 根据积分比例换算可折扣的金额 (元) */
  const creditDeductPrice = MathDivide(credit, MerchantModule.pointExchangeRate);
  /* 实际价格（元） */
  const price = calcMoney(priceCent);
  /* 已减去折扣的金额（元） */
  let deductedPrice = Number(MathSubtract(price, creditDeductPrice));
  if (deductedPrice < 0) {
    credit = priceCent;
    deductedPrice = 0;
  }

  return {
    /* 已折扣金额 分 */
    price: calcMoneyCent(deductedPrice),
    credit
  };
};

/* 读取折扣积分 */
export const calcDeductCredit = (detail: Pick<IBaseProduct, 'inputRules'>) => {
  if (detail.inputRules) {
    const inputRules = JSON.parse(detail.inputRules);
    const deductPrice = Number(inputRules?.deductPrice ?? 0);
    // 根据比例换算可抵扣积分
    return MathMultiply(MathDivide(deductPrice, 100), MerchantModule.pointExchangeRate);
  }
  return 0;
};
