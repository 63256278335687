/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-25 19:13:40
 * @FilePath: /mall-front-static/src/api/merchant/index.ts
 * @Description:
 *
 */
import request from '@/utils/request';
import { IRequestResult } from '../type/tool';

export type IBaseMerchantConfig = {
  /* 对应路由替换 */
  merchantFrontFlag?: string;
  /* 商户appid */
  meansAppId: string;
  /* 商户id */
  merchantId: string;
  /* 商户名 */
  merchantName: string;
  /* 积分汇率 现金比积分 */
  pointExchangeRate: number;
  // 积分
  payPointId: number;
  // 成长值
  growthPointId: number;
  // 直购券
  couponPointId: number;
  // 积分商品ID
  pointSpuId: number;
  // 用户协议
  userAgreement: string;
};

// 获取商户信息
export function getMerchantConfig(): Promise<IRequestResult<IBaseMerchantConfig>> {
  return request.get('/merchant/merchant_config/front/info', { hideMsg: true });
}
