















import { Vue, Component, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
@Component({
  name: 'NestedRouterView'
})
export default class extends Vue {
  keepAlive = false;
  @Watch('$route', { immediate: true })
  onChange(val: Route) {
    if (val.meta) {
      this.keepAlive = val.meta.keepAlive || false;
      return;
    }
    this.keepAlive = false;
  }

  goBack() {
    if (window.history.length <= 1) {
      this.$router.push({ name: 'Index' });
      return;
    }

    this.$router.go(-1);
  }
}
