






















import { Vue, Component } from 'vue-property-decorator';
import anime from 'animejs';
@Component({
  name: 'MainRouterView'
})
export default class extends Vue {
  curScrollTop = 0;

  /* 生成滚动到顶部动画 */
  get animeScrollTop() {
    return anime({
      autoplay: false,
      targets: this.myContainer,
      scrollTop: 0,
      easing: 'easeInOutCubic',
      duration: 300
    });
  }

  get myContainer() {
    return this.$refs.myContainer;
  }

  goTop() {
    this.animeScrollTop.play();
  }

  windowScroll(e: Event) {
    if (e.target) {
      this.curScrollTop = (e.target as HTMLElement).scrollTop;
    }
  }

  created() {
    window.addEventListener('scroll', this.windowScroll);
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.windowScroll);
  }
}
