













import { Vue, Component, Provide } from 'vue-property-decorator';
import { autoSendCoupon } from './api/user/coupon';
import wxApi from './lib/jsskd';
@Component({
  name: ''
})
export default class extends Vue {
  isRootShow = true;

  @Provide()
  appReload() {
    this.isRootShow = false;
    this.$nextTick(() => {
      this.isRootShow = true;
    });
  }

  created() {
    wxApi.init();
  }
}
