/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-24 14:51:53
 * @FilePath: /mall-front-static/src/router/guard/addRouter.ts
 * @Description:
 *
 */
import { Toast } from 'vant';
import { MerchantModule } from '@/store/modules/Merchant';
import { projectRoutes } from './../../project/zjrb/router/projectRoutes';
import { RouteConfig } from 'vue-router';
import { baseRoutes, createRouter } from './../instance';
import router from '@/router/instance';
import { isEmpty } from 'lodash';

let isReplaceRoute = false;

router.beforeEach(async (to, from, next) => {
  if (!to.params.merchantAppid) {
    Toast('查询不到商户id');
    return;
  }
  if (!MerchantModule.merchantId) {
    /* 先插入appid 请求时需要 */
    MerchantModule.SET_MERCHANT_APPID(to.params.merchantAppid);
    await MerchantModule.getMerchantConfig();
  }

  if (!isReplaceRoute) {
    isReplaceRoute = true;
    /* 如果有独立route标识 替换router */
    if (MerchantModule.merchantFrontFlag) {
      replaceRoute();
      next({ path: to.path, query: to.query, replace: true });
      return;
    }
  }
  next();
});

/* 替换路由 */
function replaceRoute() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher;
  const list = filterRoute(projectRoutes[MerchantModule.merchantFrontFlag], baseRoutes);
  list.forEach(item => router.addRoute(item));
}
/* 过滤路由 */
function filterRoute(routes: Partial<RouteConfig>[], routeLst: Array<RouteConfig>) {
  return routeLst.map(route => {
    routes.forEach(element => {
      if (route.children) {
        route.children = filterRoute(routes, route.children);
      }

      if (element.name === route.name && !isEmpty(element.name)) {
        Object.assign(route, element);
      }
    });
    return route;
  });
}
