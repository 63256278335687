import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NestedRouterView from '@/components/NestedRouterView/index.vue';
import MainRouterView from '@/components/MainRouterView/index.vue';
import ChildrenView from '@/components/NestedRouterView/ChildrenView.vue';

Vue.use(VueRouter);

export const baseRoutes: Array<RouteConfig> = [
  {
    path: '/:merchantAppid/',
    component: MainRouterView,
    children: [
      {
        path: '',
        component: () => import('@/components/TabRouterView/index.vue'),
        children: [
          {
            path: 'index',
            name: 'Index',
            component: () => import('@/views/index/index.vue'),
            meta: {
              title: '首页'
            }
          },
          {
            path: 'category',
            name: 'Category',
            component: () => import('@/views/category/index.vue'),
            meta: {
              title: '分类'
            }
          },
          {
            path: 'shopping-car',
            name: 'ShoppingCar',
            component: () => import('@/views/shopping-car/index.vue'),
            meta: {
              title: '购物车'
            }
          },
          {
            path: 'mine',
            name: 'Mine',
            component: () => import('@/views/mine/index.vue'),
            meta: {
              title: '个人中心'
            }
          }
        ]
      },
      {
        path: '',
        component: NestedRouterView,
        children: [
          {
            path: 'category-detail/:id',
            component: () => import('@/views/index/category-detail.vue')
          },
          {
            path: 'mine/coupon',
            name: 'MineCoupon',
            component: () => import('@/views/mine/coupon/coupon.vue'),
            meta: {
              title: '优惠券'
            }
          },
          {
            path: 'mine/card',
            meta: {
              title: '兑换卡密'
            },
            component: () => import('@/views/mine/card.vue')
          },
          {
            path: 'mine/issue',
            meta: {
              title: '常见问题'
            },
            component: () => import('@/views/mine/issue.vue')
          },
          {
            path: 'mine/collection',
            name: 'MineCollection',
            meta: {
              title: '我的收藏'
            },
            component: () => import('@/views/mine/collection.vue')
          },
          {
            path: 'mine/credit',
            meta: {
              title: '我的积分'
            },
            component: ChildrenView,
            children: [
              {
                path: '',
                name: 'MineCredit',
                component: () => import('@/views/mine/credit/index.vue')
              },
              {
                path: 'detail',
                name: 'MineCreditDetail',
                component: () => import('@/views/mine/credit/detail.vue')
              }
            ]
          },
          {
            path: 'address',
            component: ChildrenView,
            children: [
              {
                path: 'list',
                name: 'AddressList',
                meta: {
                  title: '地址列表'
                },
                component: () => import('@/views/address/address-list.vue')
              },
              {
                path: 'edit',
                name: 'AddressEdit',
                meta: {
                  title: '地址编辑'
                },
                component: () => import('@/views/address/address-edit.vue')
              }
            ]
          },

          {
            path: 'create-order',
            component: ChildrenView,
            children: [
              {
                path: 'confirm',
                name: 'ConfirmOrder',
                component: () => import('@/views/create-order/confirm-order.vue'),
                meta: {
                  title: '确认订单'
                }
              },
              {
                path: 'confirm-coupon',
                name: 'ConfirmCoupon',
                component: () => import('@/views/create-order/confirm-coupon.vue'),
                meta: {
                  title: '确认兑换'
                }
              },
              {
                path: 'res',
                name: 'buyRes',
                component: () => import('@/views/create-order/buy-res.vue'),
                meta: {
                  title: '支付结果'
                }
              }
            ]
          },
          {
            path: 'product/:id',
            name: 'ProductDetail',
            component: () => import('@/views/product/index.vue')
          },
          {
            path: 'hot-rank',
            component: () => import('@/views/index/hot-rank.vue'),
            meta: {
              title: '热销榜单'
            }
          },
          {
            path: 'news/:code',
            component: () => import('@/views/index/news-detail.vue')
          },
          {
            path: 'deputy-shopping-car',
            name: 'DeputyShoppingCar',
            component: () => import('@/views/shopping-car/index.vue'),
            meta: {
              title: '购物车'
            }
          },
          {
            path: 'search',
            name: 'Search',
            component: () => import('@/views/search/index.vue'),
            meta: {
              keepAlive: true,
              title: '搜索'
            }
          },
          {
            path: 'search/discount/:id',
            name: 'SearchDiscount',
            component: () => import('@/views/search/discount.vue'),
            meta: {
              title: '搜索'
            }
          },
          {
            path: 'order',
            component: ChildrenView,
            children: [
              {
                path: 'list',
                name: 'OrderList',
                meta: {
                  title: '订单列表',
                  keepAlive: true
                },
                component: () => import('@/views/order/order-list/index.vue')
              },
              {
                path: 'detail/:orderCode',
                name: 'OrderDetail',
                meta: {
                  title: '订单详情'
                },
                component: () => import('@/views/order/order-detail/index.vue')
              },
              {
                path: 'express/:orderCode',
                name: 'OrderExpress',
                meta: {
                  title: '订单物流详情'
                },
                component: () => import('@/views/order/order-express/index.vue')
              },
              {
                path: 'code/:orderCode',
                name: 'OrderCardCode',
                meta: {
                  title: '查看权益'
                },
                component: () => import('@/views/order/virtual-card/index.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'login',
        name: 'Login',
        meta: {
          title: '登录'
        },
        component: () => import('@/views/login/index.vue')
      }
    ]
  }
];

export const createRouter = (constantRoutes?: RouteConfig[]) =>
  new VueRouter({
    mode: 'history',
    base: '/',
    routes: constantRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: document.documentElement.scrollTop };
      }
    }
  });

const router = createRouter(baseRoutes);
export default router;
