import { MerchantModule } from '@/store/modules/Merchant';
import request from '@/utils/request';
import { IRequestResult } from '../type/tool';

export const DictConfigName = {
  // 商品热门搜索
  get HOT_WORD() {
    return `mall_${MerchantModule.merchantIdAppId}_hotWord`;
  },
  // 常见问题
  get QUESTION() {
    return `mall_${MerchantModule.merchantIdAppId}_question`;
  },
  // 金刚区
  get VAJRA_AREA() {
    return `mall_${MerchantModule.merchantIdAppId}_home_vajraArea`;
  },
  // 商品推荐
  get RECOMMEND() {
    return `mall_${MerchantModule.merchantIdAppId}_home_recommend`;
  },
  // 首页轮播
  get BANNER() {
    return `mall_${MerchantModule.merchantIdAppId}_home_banner`;
  },
  // 头条
  get NEWS() {
    return `mall_${MerchantModule.merchantIdAppId}_news`;
  },
  // 会员
  get MEMBER() {
    return `mall_${MerchantModule.merchantIdAppId}_memberLevel`;
  },
  /* 生日礼物 */
  get BIRTHDAY() {
    return `mall_${MerchantModule.merchantIdAppId}_birthday`;
  },
  /* 个人中心数据面板 */
  get USER_CENTER() {
    return `mall_${MerchantModule.merchantIdAppId}_userCenter`;
  }
};
type DictConfigKey = keyof typeof DictConfigName;

// 字典项
export type DictItem = {
  dictCode: string;
  id: number;
  itemCode: string;
  itemContent: string;
  itemDescription: string;
  itemIcon: string;
  itemName: string;
  itemOrder: string;
  tags: string;
};

// 根据字典编码查询字典项   查询首页配置项
export function getDictConfig(dictCode: DictConfigKey): Promise<IRequestResult<DictItem[]>> {
  return request.get(`/merchant/open/dict/query/item/${DictConfigName[dictCode]}`, { hideMsg: true });
}

// 根据字典编码和字典项编码获取字典项
export function getDictConfigByCode(dictCode: DictConfigKey, itemCode: string): Promise<IRequestResult<DictItem>> {
  return request.get(`/merchant/open/dict/query/item/info/${DictConfigName[dictCode]}/${itemCode}`, { hideMsg: true });
}

// 查询首页通知
export function getHomeNotice(): Promise<
  IRequestResult<{
    news?: DictItem[];
    staffList?: {
      userId: string;
      staffName: string;
      staffDepartment: string;
    }[];
  }>
> {
  return request.get('/merchant/merchant/getMerchantNotice/' + DictConfigName.NEWS, { hideMsg: true });
}

export function wxSdkConfig(param: { fullUrl: string }): Promise<
  IRequestResult<{
    appId: string;
    nonceStr: string;
    signature: string;
    timeStamp: string;
  }>
> {
  return request.get('/user/wechatAuth/jsConfig?url=' + param.fullUrl, { hideMsg: true });
}
