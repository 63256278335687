import dayjs from 'dayjs';
import Vue from 'vue';
import calcCredit from './calcCredit';
import { calcMoney, formatMoney } from './calcMoney';
export function formatTime(time: Parameters<typeof dayjs>[0], format = 'YYYY-MM-DD HH:mm:ss') {
  if (!time) return '';
  return dayjs(time).format(format);
}

Vue.filter('formatTime', formatTime);
Vue.filter('formatMoney', formatMoney);
Vue.filter('calcMoney', calcMoney);

Vue.filter('calcCredit', calcCredit);
