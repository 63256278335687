/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-25 19:18:19
 * @FilePath: /mall-front-static/src/store/modules/Merchant.ts
 * @Description:
 *
 */
import { getMerchantConfig, IBaseMerchantConfig } from '@/api/merchant/index';
import store from '@/store';
import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
@Module({ store, name: 'Merchant', dynamic: true })
class MerchantStore extends VuexModule implements IBaseMerchantConfig {
  /* 迷思商户appid */
  meansAppId = '';
  /* 商城商户appid */
  merchantIdAppId = '';
  /* 商户id */
  merchantId = '';
  /* 积分汇率 现金比积分 */
  pointExchangeRate = NaN;
  // 积分
  payPointId = NaN;
  // 成长值
  growthPointId = NaN;
  // 直购券
  couponPointId = NaN;
  // 积分商品ID
  pointSpuId = NaN;
  // 用户协议
  userAgreement = '';
  // 商户名
  merchantName = '';

  merchantFrontFlag = '';

  @Mutation
  SET_MERCHANT(config: IBaseMerchantConfig) {
    Object.keys(config).forEach(key => {
      const value = config[key as keyof IBaseMerchantConfig];
      (this[key as keyof IBaseMerchantConfig] as any) = value;
    });
  }

  @Mutation
  SET_MERCHANT_APPID(appid: string) {
    this.merchantIdAppId = appid;
  }

  @Action
  async getMerchantConfig() {
    const { payload } = await getMerchantConfig();
    this.SET_MERCHANT(payload);
  }
}

export const MerchantModule = getModule(MerchantStore);
