import { IRequestPageResult } from '@/api/type/tool';
import router from '@/router';
import { MerchantModule } from '@/store/modules/Merchant';
// import router from '@/router';
import axios from 'axios';
import { Toast } from 'vant';
import { getToken, removeToken } from './storage';

const request = axios.create({
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
  baseURL: process.env.VUE_APP_API_PATH
});

let loading = false;
request.interceptors.request.use(config => {
  if (config.headers) {
    config.headers.authorization = getToken();
    config.headers.appId = MerchantModule.merchantIdAppId;
    if (config.loading && !loading) {
      loading = true;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: config.loadingText !== undefined ? config.loadingText : '数据请求中'
      });
    }
  }
  return config;
});

request.interceptors.response.use(async response => {
  const config = response.config;

  if (loading) {
    Toast.clear(false);
    loading = false;
  }

  const res = response.data;
  if (res.status === 200) {
    if (!config.hideMsg && res.message) {
      Toast.success({ message: config.msgText || res.message, icon: 'success', duration: 2000 });
    }
    return res;
  } else {
    if (res.status === 403) {
      removeToken();
      if (res.payload === 'noToken') {
        return await request(config);
      }
      Toast('登录已过期');

      router.push({ name: 'Login', query: { backUrl: encodeURIComponent(router.currentRoute.fullPath) } });

      return Promise.reject(res.error);
    }

    if (!config.hideLogicalMsg && res.error) {
      Toast.fail({ message: res.error, icon: 'none', duration: 2000 });
    }
    return Promise.reject(res.error);
  }
});

export default request;

export function baseQueryByPage<S = Record<string, any>, T = any, R = IRequestPageResult<T>>(url: string) {
  return function (searchObj = {} as S, current = 1, size = 10): Promise<R> {
    return request.post(url, { ...searchObj, current, size }, { hideMsg: true });
  };
}
