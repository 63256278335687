import Clipboard from 'clipboard';
import { Toast } from 'vant';
import { DirectiveBinding } from 'vue/types/options';

export default {
  bind(el: Element, binding: DirectiveBinding) {
    const clipboard = new Clipboard(el, {
      text: () => binding.value
    });

    clipboard.on('success', () => {
      Toast('复制成功!');
    });

    clipboard.on('error', () => {
      Toast('复制失败，请手动选择复制!');
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    el._vClipBoard = clipboard;
  },
  update(el: Element, binding: DirectiveBinding) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    el._vClipBoard.text = function () {
      return binding.value;
    };
  }
};
