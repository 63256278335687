const zjrbRouter = [
  {
    name: 'Index',
    component: () => import('@/project/zjrb/index/index.vue')
  },
  // {
  //   name: 'ProductDetail',
  //   component: () => import('@/project/zjrb/product/index.vue')
  // },
  {
    name: 'ConfirmOrder',
    component: () => import('@/project/zjrb/create-order/index.vue')
  },
  {
    name: 'OrderDetail',
    component: () => import('@/project/zjrb/order-detail/index.vue')
  }
];
export default zjrbRouter;
