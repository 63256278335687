/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-25 12:11:22
 * @FilePath: /mall-front-static/src/filters/calcMoney.ts
 * @Description:
 *
 */
import { MathDivide, MathMultiply } from './../utils/math';
import { MerchantModule } from '@/store/modules/Merchant';
/* 根据分来计算元 */
export function calcMoney(value: number | string) {
  value = Number(value || 0);
  return Number(MathDivide(value, 100));
}
/* 根据元来计算分 */
export function calcMoneyCent(value: number | string) {
  value = Number(value || 0);
  return Number(MathMultiply(value, 100));
}
/* 根据积分来计算元 */
export function calcMoneyCredit(value: number | string) {
  return MathDivide(value, MerchantModule.pointExchangeRate || 1);
}

export function formatMoney(value: number | string, isCalc = true) {
  if (!value) return '0.00';
  value = Number(value);
  /* 是否不进行计算 */
  const calcValue = isCalc ? Number(calcMoney(value)) : value;
  let f = parseFloat(calcValue.toString());
  f = +calcValue.toFixed(2);
  let s = f.toString();
  let rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s || '0.00';
}
