import { AddressItem } from './../api/user/address';
import { IUserInfo } from '@/api/user';
export const TOKEN_KEY = '_tk';
export const USER_KEY = '_uk';
/* 购买使用的地址 */
export const BUY_ADDRESS_KEY = '_bak';

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  const str = localStorage.getItem(TOKEN_KEY);
  return str || '';
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function setUserInfo(info: IUserInfo) {
  localStorage.setItem(USER_KEY, JSON.stringify(info));
}

export function getUserInfo(): IUserInfo {
  const str = localStorage.getItem(USER_KEY);
  if (str) return JSON.parse(str);
  return {} as IUserInfo;
}

export function removeUserInfo() {
  localStorage.removeItem(USER_KEY);
}

export function getBuyAddress(): AddressItem | void {
  const str = localStorage.getItem(BUY_ADDRESS_KEY);
  if (str) return JSON.parse(str);
  return;
}

export function setBuyAddress(info: AddressItem) {
  localStorage.setItem(BUY_ADDRESS_KEY, JSON.stringify(info));
}

export function removeBuyAddress() {
  localStorage.removeItem(BUY_ADDRESS_KEY);
}
