/*
 * @Author: Jc
 * @Date: 2021-12-27 16:52:28
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-24 14:58:01
 * @FilePath: /mall-front-static/src/utils/vant.ts
 * @Description:
 *
 */
import Vue from 'vue';
// eslint-disable-next-line prettier/prettier
import { AddressEdit, AddressList, Button, Cascader, Cell, Checkbox, CountDown, DatetimePicker, Divider, Field, Form, Grid, GridItem, Icon, Image, List, NoticeBar, Popup, Search, Sidebar, SidebarItem, Sku, Sticky, Swipe, SwipeCell, SwipeItem, Switch, Tab, Tabbar, TabbarItem, Tabs, Tag, CellGroup } from 'vant';
Vue.use(DatetimePicker);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Swipe);
Vue.use(List);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(SwipeItem);
Vue.use(Image);
Vue.use(NoticeBar);
Vue.use(Sku);
Vue.use(Switch);
Vue.use(Cascader);
Vue.use(Sticky);
Vue.use(Checkbox);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Cell);
Vue.use(AddressList);
Vue.use(AddressEdit);
Vue.use(Search);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Form);
Vue.use(Field);
Vue.use(CountDown);
Vue.use(SwipeCell);
Vue.use(CellGroup);
