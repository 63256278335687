import { wxSdkConfig } from '@/api/other';
const wxApi = {
  init: async () => {
    const { payload: config } = await wxSdkConfig({
      fullUrl: encodeURIComponent(window.location.href)
    });
    wx.config({
      ...config,
      beta: false,
      timestamp: config.timeStamp,
      debug: false,
      jsApiList: ['chooseWXPay']
    });

    wx.ready(() => {
      console.log('初始化成功');
    });

    wx.error(() => {
      console.log('加载失败');
    });
  },
  onPay(payConfig: any) {
    console.log(payConfig)
    return new Promise((resolve, reject) => {
      wx.chooseWXPay({
        ...payConfig,
        timestamp: payConfig.timeStamp,
        success(res: any) {
          resolve(res);
        },
        fail(res: any) {
          if (res.errMsg === 'chooseWXPay:cancel') {
            res.errMsg = '支付取消';
          }
          reject(res);
        },
        cancel(res: any) {
          reject(res);
        }
      });
    });
  }
};

export default wxApi;
